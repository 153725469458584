import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'SEIC SAS', // e.g: 'Name | Developer'
  lang: 'es', // e.g: en, es, fr, jp
  description: 'Expertos en automatización', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: '',
  name: '',
  subtitle: '',
  cta: '',
  img: '',
  logo: 'logo.png',
};

// ABOUT DATA
export const aboutData = {
  img: 'about.jpg',
  paragraphOne: 'SEIC Automatización S.A.S está compuesto por un equipo de ingeniería que da solución a sus requerimientos, teniendo como base la utilización de productos de marcas reconocidas y de amplio respaldo de la mano de profesionales dispuestos a acompañar sus procesos de modernización; dando cumplimiento a los estándares internacionales, siempre de la manera más cordial.',
  paragraphTwo: '',
  paragraphThree: '',
  resume: '', // if no resume, the button will not show up
};

export const partnersData = {
  // img: 'partner_ phoenix.png',
  img2: 'partner_rittal.png',
  img2_url: 'https://www.rittal.com/co-es/content/es/start/',
}

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'project_gabinete.JPG',
    title: 'GABINETES DE CONTROL',
    info: 'Nuestro principal compromiso es la fabricación de gabinetes de calidad, con componentes tecnológicos de punta, por esta razón, contamos con la herramienta específica para cada labor. Nuestros gabinetes son diseñados bajo normas vigentes y cumplen con los requisitos de sitio donde se requieran instalar.',
    info2: 'SEIC Automatización cuenta con personal calificado para la instalación de gabinetes en cualquier tipo de condición. Adicional a esto contamos con sistemas de marcación.',
    url: 'https://api.whatsapp.com/send?phone=573014768513&text=Quiero%20cotizar%20un%20gabinete',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project_diseno.JPG',
    title: 'DISEÑO Y MONTAJE NEUMÁTICO',
    info: 'De acuerdo con el amplio uso de los sistemas neumáticos, SEIC se involucra como aliado estratégico en el diseño, montaje y mantenimiento de redes de aire comprimido en industrias.',
    info2: 'Con proveedores y empresas exclusivas según la necesidad, contamos con un grupo idóneo para la planeación y adecuación de los mejores elementos. Equipos de avanzada para la detección de fugas y optimización de redes neumáticas.',
    url: 'https://api.whatsapp.com/send?phone=573014768513&text=Quiero%20cotizar%20un%20dise%C3%B1o',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project_programacion.jpg',
    title: 'PROGRAMACIÓN PLC Y HMI',
    info: '',
    info2: '',
    markup: {
      __html: `
      <p>Somos una empresa de ingenieros con alto enfoque en la Automatización de procesos industriales, manejamos cualquier tipo de lenguaje o/y plataforma de programación. Manejamos plataformas como:<p>
      <ul>
        <li><p>ISPSOFT</p></li>
        <li><p>TIA PORTAL</p></li>
        <li><p>CODESYS</p></li>
      </ul>
      <p>Entre otros; y ofrecemos un completo portafolio de servicios en Automatización.</p>
      <ul>
        <li><p>Programación de PLCs.</p></li>
        <li><p>Interfaz táctil de visualización HMI.</p></li>
        <li><p>Programación de computadores industriales.</p></li>
        <li><p>Sistematización de control SCADA.</p></li>
        <li><p>Control de movimiento.</p></li>
      </ul>
`
    },
    url: 'https://api.whatsapp.com/send?phone=573014768513&text=Quiero%20cotizar%20una%20programacion',
    repo: '', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: 'Déjanos un mensaje',
  btn: '',
  email: '',
};

// FOOTER DATA
export const footerData = {
  networks: [
    // {
    //   id: nanoid(),
    //   name: 'twitter',
    //   url: '',
    // },
    // {
    //   id: nanoid(),
    //   name: 'codepen',
    //   url: '',
    // },
    // {
    //   id: nanoid(),
    //   name: 'linkedin',
    //   url: '',
    // },
    // {
    //   id: nanoid(),
    //   name: 'github',
    //   url: '',
    // },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
